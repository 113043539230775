import React, { ReactNode, PropsWithChildren } from 'react';
import * as Styled from './styles';
import { LoaderCircle, loaderTypes } from '../../containers/loader-circle/loader-circle';

export enum ButtonViewEnum {
    narrow,
}

interface ButtonProps {
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    secondary?: boolean;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit';
    loading?: boolean;
    beforeIcon?: ReactNode;
    view?: ButtonViewEnum;
}

const loaderColors = {
    secondary: loaderTypes.dark,
    primary: loaderTypes.light,
    disabled: loaderTypes.dark,
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    children,
    className,
    onClick,
    secondary,
    disabled,
    type = 'button',
    loading,
    beforeIcon,
    view,
}) => {
    let loaderColor = loaderColors.primary;
    if (secondary) {
        loaderColor = loaderColors.secondary;
    } else if (disabled) {
        loaderColor = loaderColors.secondary;
    }

    return (
        <Styled.Button
            className={className}
            onClick={onClick}
            secondary={secondary}
            disabled={disabled}
            type={type}
            loading={loading}
            view={view}
        >
            {beforeIcon && (
                <Styled.BeforeIcon loading={loading} disabled={disabled}>
                    {beforeIcon}
                </Styled.BeforeIcon>
            )}
            {children}
            {loading && <Styled.LoaderWrapper>{<LoaderCircle type={loaderColor} />}</Styled.LoaderWrapper>}
        </Styled.Button>
    );
};

export default Button;
