import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { SearchDistinctProduct } from '../../models/search-entity';
import { transliterate } from '../gtm.helper';
import { GtmService } from '../web-analytics.helpers';

export class SearchFiltersAnalytics extends GtmService {
    static openFiltersEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.filtry,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        });

        this.log(this.openFiltersEvent.name);
    }

    static startSearchRequestHome(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.startSearchRequestHome.name);
    }

    static searchFieldFocus(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent,
        });
        this.log(this.searchFieldFocus.name);
    }

    static clearFilters() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: WebAnalyticsEventLabelsEnum.reset,
        });
        this.log(this.clearFilters.name);
    }

    static closeFiltersOnMobile() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: WebAnalyticsEventLabelsEnum.filters,
        });
        this.log(this.closeFiltersOnMobile.name);
    }

    static acceptFiltersOnMobile() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: WebAnalyticsEventLabelsEnum.filters_accept,
        });

        this.log(this.acceptFiltersOnMobile.name);
    }

    static changeProduct(product: SearchDistinctProduct, selected: boolean) {
        const eventAction = selected ? WebAnalyticsEventActionEnum.filterAdd : WebAnalyticsEventActionEnum.filterRemove;
        const eventLabel = transliterate(product.name, true) as string | undefined;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction,
            eventLabel,
        });
        this.log(this.changeProduct.name);
    }

    static clearProducts() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.filterAdd,
            eventLabel: WebAnalyticsEventLabelsEnum.allServices,
        });
        this.log(this.clearProducts.name);
    }

    static searchProductArticleClick(questionName: string, productName?: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.cardOpen,
            eventLabel: transliterate(questionName, true),
            productName: transliterate(productName, true),
        });
        this.log(this.searchProductArticleClick.name);
    }

    static searchError(productName: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.rejected,
            eventLabel: WebAnalyticsEventLabelsEnum.search,
            eventContent: productName,
            eventContext: WebAnalyticsEventLabelsEnum.searchNoResults,
        });
        this.log(this.searchError.name);
    }

    static searchMoreProductsClick(productSectionName?: string) {
        const eventContent = productSectionName
            ? transliterate(productSectionName, true)
            : WebAnalyticsEventContentEnum.home;

        const productName = productSectionName ? transliterate(productSectionName, true) : null;

        this.pushEvent({
            event: WebAnalyticsEventEnum.vntPoisk,
            eventCategory: WebAnalyticsEventCategoryEnum.search,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.more,
            eventValue: null,
            eventContent,
            eventContext: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName,
        });
        this.log(this.searchMoreProductsClick.name);
    }
}
