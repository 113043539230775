export enum SegmentsEnum {
    individuals = 'individuals',
    business = 'business',
    partners = 'partners',
}

export enum ProductSegmentEnum {
    businesses = 'businesses',
    individuals = 'individuals',
    partners = 'partners',
}
