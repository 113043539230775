import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { autofaqAppGlobalStyles, autofaqGlobalStyles } from './autofaq-global';
import { mtsEditorGlobalStyles } from './mts-editor-global';
import { defaultHeaderHeight, segmentsLineHeight } from '../../constants/header';
import { Themeinterface } from '../themes/theme.type';

// чтобы после скролла по якорю, контент не был строго прибит к краю экрана
const scrollIndentFromHeader = 20;
const scrollPadding = defaultHeaderHeight + scrollIndentFromHeader + segmentsLineHeight;

const GlobalStyle = createGlobalStyle<{ theme: Themeinterface; isApp: boolean }>`
    ${reset}

    html {
        scroll-padding-top: ${scrollPadding}px;
    }

    body {
        color: ${({ theme }) => theme.textPrimary};

        ${mtsEditorGlobalStyles};
        ${autofaqGlobalStyles};

        ${({ isApp }) => isApp && autofaqAppGlobalStyles}
    }

    html, body {
        font-family: MTSCompact-Regular, sans-serif;
        --ecosystem-header-font-family: 'MTS Compact';
        background-color: ${({ theme }) => theme.backgroundPrimary};
    }

    :root {
        --ecosystem-header-font-family: 'MTS Compact';
    }

    * {
        overflow-wrap: break-word;
    }

    img,
    video,
    code,
    pre {
        max-width: 100%;
    }

    html,
    button,
    input,
    select,
    textarea {
        font: inherit;
    }

    figure.table {
        overflow-x: auto;
    }

    table, th, td {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #bbc1c7;
    }

    strong {
        font-family: MTSText-Medium, sans-serif;
    }

    ol {
        list-style: decimal;
    }
`;

export { GlobalStyle };
